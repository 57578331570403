/* eslint-disable linebreak-style */
/* eslint-disable import/extensions */

// Services Img Imports
import Web from "../assets/images/Services/Web.png";
import Mobile from "../assets/images/Services/Mobile.png";
import UIUX from "../assets/images/Services/Design.png";

// Portfolio Img Imports
import Recruiting from "../assets/images/Portfolio/Recruiting.png";
import Stream from "../assets/images/Portfolio/Stream.png";
import Freelance from "../assets/images/Portfolio/Freelance.png";
import Aura from "../assets/images/Portfolio/Aura.png";
import Surtido from "../assets/images/Portfolio/Surtido.png";
import ManagementApp from "../assets/images/Portfolio/ManagementApp.png";

// Advantages
import Communicative from "../assets/images/Advantages/Communicative.png";
import Collaborative from "../assets/images/Advantages/Collaborative.png";
import Management from "../assets/images/Advantages/Management.png";
import Favorite from "../assets/images/Advantages/Favorite.png";

// Testimonials
import Sasha from "../assets/images/Testimonials/Sasha.jpg";
import Reiner from "../assets/images/Testimonials/Reiner.jpg";
import Kruger from "../assets/images/Testimonials/Kruger.jpg";

// TeamMembers
import aulia from "../assets/images/TeamMembers/CEO.jpg";
import hartono from "../assets/images/TeamMembers/HRD.jpg";
import adit from "../assets/images/TeamMembers/Finance.jpg";
import abay from "../assets/images/TeamMembers/Project-manager.jpg";
import yunita from "../assets/images/TeamMembers/Frontend1.jpg";
import adji from "../assets/images/TeamMembers/Frontend2.jpg";
import yoyo from "../assets/images/TeamMembers/Backend1.jpg";

export const Services = [
  {
    title: "Software Development",
    imageUrl: Web,
    animation: "left",
  },
  {
    title: "IT Consulting",
    imageUrl: Mobile,
    animation: "up",
  },
  {
    title: "IT Outsourcing",
    imageUrl: UIUX,
    animation: "right",
  },
];

export const Portfolios = [
  {
    id: "asd1293uasdads1",
    title: "PJAP pajak.com",
    // detail: "Remindex",
    imageUrl: Recruiting,
    type: "Mobile Apps",
    responsibility: ["Mobile Development", "UI/UX Design", "Web Development"],
    technology: ["NestJS", "Flutter", "Docker", "MongoDB"],
    features: [
      "E-Reg NPWP",
      "E-Filling SPT",
      "E-Billing Tax",
      "Robust Dashboard",
    ],
    credit: "PT Taxologis Prima Saga",
  },
  {
    id: "asd1293uhjkhkjh2",
    title: "Pallet Management System",
    // detail: "Remindex",
    imageUrl: Stream,
    type: "Web Apps",
    responsibility: ["Web Development", "UI/UX Design"],
    technology: ["ExpressJS", "VueJS", "Docker", "PostgreSQL"],
    features: [
      "Progresive Web Application",
      "Dashboard Monitoring",
      "Pallet Inventory Tracking",
      "Pallet Delivery Tracking",
      "Pallet Damage & Lost Management",
    ],
    credit: "Semen Indonesia Group",
  },
  {
    id: "asd1293uvbvcbbd3",
    title: "Remindex",
    // detail: "Remindex",
    imageUrl: Freelance,
    type: "Web Apps",
    responsibility: ["Web Development", "UI/UX Design"],
    technology: ["Laravel", "NginX", "MySQL"],
    features: ["Document Reminder", "Reminder Dashboard", "Email Reminder"],
    credit: "SC Johnson Indonesia",
  },
  {
    id: "asd1293ufgdfgs4",
    title: "TanpaPerantara.co.id",
    // detail: "Remindex",
    imageUrl: Aura,
    type: "Web Apps",
    responsibility: ["Web Development", "UI/UX Design"],
    technology: ["Laravel", "NextJS", "NginX", "PostgreSQL"],
    features: [
      "Property Listing",
      "Payment Gateway",
      "Home Service",
      "Business Partner",
    ],
    credit: "Tanpa Perantara Group",
  },
  {
    id: "asd1293ulskmnb5",
    title: "Tufpal Systems",
    // detail: "Remindex",
    imageUrl: Surtido,
    type: "Web Apps",
    responsibility: ["Web Development", "UI/UX Design"],
    technology: ["ExpressJS", "VueJS", "Docker", "PostgreSQL"],
    features: [
      "Progresive Web Application",
      "Dashboard Monitoring",
      "Pallet Inventory Tracking",
      "Pallet Delivery Tracking",
      "Pallet Damage & Lost Management",
    ],
    credit: "PT Indocement",
  },
  {
    id: "asd1293ulkmnbj6",
    title: "Learning Management Systems",
    // detail: "Remindex",
    imageUrl: ManagementApp,
    type: "Web Apps",
    responsibility: ["Web Development", "UI/UX Design"],
    technology: ["Native PHP", "NginX", "MySQL"],
    features: ["Course Management", "Quiz Management", "Training Path"],
    credit: "SC Johnson Indonesia",
  },
];

export const Advantages = [
  [
    {
      title: "Communicative",
      description:
        "We communicate our project ideas and progress to make it clear.",
      imageUrl: Communicative,
    },
    {
      title: "Management",
      description:
        "We manage our project properly to make our project done well.",
      imageUrl: Management,
    },
  ],
  [
    {
      title: "Collaborative​",
      description:
        "Our team are very collaborative to make our project done well.",
      imageUrl: Collaborative,
    },
    {
      title: "Favorite",
      description: "We've did so many project and all of our client love it.",
      imageUrl: Favorite,
    },
  ],
];
export const Testimonials = [
  {
    id: 1,
    name: "Sasha Rose",
    company: "Owner, Surveyor Corps",
    testimoni:
      "Thanks for Racxstudio, you guys are the best! Keep up the great work!",
    imageUrl: Sasha,
  },
  {
    id: 2,
    name: "Kruger Khan",
    company: "Director, Shultan Oil",
    testimoni:
      "I just wanted to let you know that it’s been great working with Racxstudio.",
    imageUrl: Kruger,
  },
  {
    id: 3,
    name: "Reiner John",
    company: "CEO, Marley CO",
    testimoni: "Racxstudio is so great. Thank you so much for a job well done.",
    imageUrl: Reiner,
  },
];

export const TeamMembers = [
  {
    name: "Aulia Harvy",
    position: "Chief Executive Officer",
    imageUrl: aulia,
  },
  {
    name: "Hartono Kurniawan",
    position: "Advisor of IT Architecture & Infrastructure",
    imageUrl: hartono,
  },
  {
    name: "Aditya Fathurochim",
    position: "Head of Design",
    imageUrl: adit,
  },
  {
    name: "Harby Anwardi",
    position: "Head of Developer",
    imageUrl: abay,
  },
  {
    name: "Yunita",
    position: "Head of Finance",
    imageUrl: yunita,
  },
  {
    name: "Adji Sudradji",
    position: "Business Development and Relation",
    imageUrl: adji,
  },
  {
    name: "E.R Pujianto N",
    position: "Business Representative and Relation",
    imageUrl: yoyo,
  },
];
