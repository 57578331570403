/* eslint-disable linebreak-style */
/* eslint-disable comma-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */
import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Spline from "@splinetool/react-spline";

import Button from "../elements/Button";

// import BuildWebsite from "../assets/images/hero/BuildWebsite.png";

// Import MyComponent
// import { MyComponent } from "./HeroGradient";

export default function Hero() {
  const titleRef = useRef(null);
  const paragraphRef = useRef(null);
  const buttonRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      defaults: { duration: 1, ease: "power4.out" },
    });

    // Initial state for elements
    gsap.set(
      [
        titleRef.current,
        paragraphRef.current,
        buttonRef.current,
        imageRef.current,
      ],
      { opacity: 0 }
    );

    tl.to(titleRef.current, {
      opacity: 1,
      y: 0,
    })
      .to(
        paragraphRef.current,
        {
          opacity: 1,
          y: 0,
        },
        "-=0.5"
      )
      .to(
        buttonRef.current,
        {
          opacity: 1,
          scale: 1,
        },
        "-=0.5"
      )
      .to(
        imageRef.current,
        {
          opacity: 1,
          x: 0,
        },
        "-=0.5"
      );
  }, []);
  return (
    <section className="hero flex flex-col-reverse lg:flex-row items-center">
      {/* <MyComponent /> */}
      <div className="w-full lg:w-1/2 xl:pl-12 sm:pr-2 mt-8">
        <h1
          ref={titleRef}
          className="text-4xl sm:text-5xl text-theme-blue font-bold leading-tight mb-5"
        >
          Innovative solutions to,
          <br />
          Deliver best service.
        </h1>

        <p
          ref={paragraphRef}
          className="font-light text-xl text-gray-400 leading-relaxed mb-16"
        >
          we dont just develop software or manage IT systems – we build
          partnerships. Our core goals revolve around understanding your unique
          business challenges and crafting IT solutions that empower you to
          achieve lasting success.
        </p>

        <Button
          ref={buttonRef}
          href="/project"
          type="link"
          className="flex w-71 h-18 items-center px-14 py-5 text-white text-xl bg-theme-purple rounded-lg shadow-2xl hover:bg-dark-theme-purple transition duration-200"
        >
          See Our Work
          <svg
            className="ml-2 w-7 h-7 text-white animate-bounce-x"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Button>
      </div>
      <div
        ref={imageRef}
        className="lg:block hidden w-full lg:w-1/2 flex justify-center items-center"
      >
        <Spline scene="https://prod.spline.design/X0TEloKc7WSQ3aSo/scene.splinecode" />
        {/* <img className="" src={BuildWebsite} alt="Build Website" /> */}
      </div>
    </section>
  );
}
